import { gql } from "@apollo/client";

//.......Companies Quries........
export const COMPANIES_DATA = gql`
  query getCompanies($parent_id: UUID) {
    companies: users(
      parent_id: $parent_id
      hasRole: { column: "name", operator: LIKE, value: "Company" }
    ) {
      id
      name
      email
      contact
      address
      role {
        name
        created_at
      }
    }
  }
`;
export const GET_COMPANY = gql`
  query getUser($id: UUID) {
    user(id: $id) {
      id
      name
      email
      contact
      address
      role {
        id
      }
    }
  }
`;
///////////////////////////////
// .....Costumers Query.........
export const COSTUMER_DATA = gql`
  query getCustomers($parent_id: UUID) {
    customers(
      user_id: $parent_id
    ) {
      id
      name
      email
      paid_per_hr
      phone
      address
      user{
        currency{
          symbol
        }
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query getCustomer($id: UUID) {
    customer(id: $id) {
      id
      name
      email
      paid_per_hr
      phone
      address
    }
  }
`;

///////////////////////////////
// .....Employee Query.........
export const EMPLOYEE_DATA = gql`
  query getEmployees($parent_id: UUID) {
    employees: users(
      parent_id: $parent_id
      hasRole: { column: "name", operator: LIKE, value: "Employee" }
    ) {
      id
      name
      email
      wage
      contact
      address
      status
      role {
        name
        created_at
      }
      company{
        currency{
          symbol
        }
      }
    }
  }
`;

// .....Work  histories Query (reports).........
export const WORK_HISTORIES = gql`
  query getWorkHistories($filter: String, $date_from: Date, $date_to: Date) {
    workHistories(filter: $filter, date_from: $date_from, date_to: $date_to) {
      id
      customer {
        name
      }
      employee {
        name
      }
      company {
        name
      }
      schedule {
        start_time
        end_time
      }
      start_time
      end_time
      location
      variance
      total_time
    }
  }
`;

export const PENDING_CHECKOUTS = gql`
  query {
    pendingCheckOuts(schedule_id: null) {
      id
      customer {
        id
        name
      }
      employee {
        name
      }
      company {
        name
      }
      images{
        image
        uploaded_time
      }
      location
      start_time
      end_time
    }
  }
`;

// Get Single Pending Checkout
export const CHECKOUT_PER_CUSTOMER = gql`
  query getCheckoutPerCustomer($id: UUID!) {
    checkOut(id: $id) {
      id
      customer {
        id
        name
        address
      }
      employee {
        id
        name
      }
      location
      company{
        id
        name
      }
      schedule{
        start_time
        end_time
      }
      images{
        image
        uploaded_time
      }
      start_time
      end_time
    }
  }
`;

// Get Checkout Images
export const CHECKOUT_IMAGES = gql`
  query getCheckoutImages($id: UUID!) {
    checkOutImages(id: $id) {
     images{
      image
      uploaded_time
      creation_time
      uploaded_from
     }
    }
  }
`;

export const SUMMARY = gql`
  query getSummary($filter: String, $date_from: Date,  $date_to: Date) {
    summary (filter: $filter, date_from: $date_from, date_to: $date_to) {
      employee_id
      total_checkouts
      total_hours
      total_minutes
      customer {
        id
        name
      }
      employee {
        name
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query getEmployee($id: UUID) {
    user(id: $id) {
      id
      name
      email
      wage
      contact
      address
      role {
        id
      }
    }
  }
`;
/////////////////////////////////////////////
/////////Schedule Mutations//////////////////
export const CUSTOMERS_EMPLOYEES = gql`
  query getCustomersAndEmployees($parent_id: UUID) {
    employees: users(
      parent_id: $parent_id
      hasRole: { column: "name", operator: LIKE, value: "Employee" }
    ) {
      ...UsersList
    }
    customers: users(
      parent_id: $parent_id
      hasRole: { column: "name", operator: LIKE, value: "Customer" }
    ) {
      ...UsersList
    }
    days {
      id
      name
    }
  }

  fragment UsersList on User {
    id
    name
    email
    contact
    company {
      id
      name
      email
    }
  }
`;

/////////////////////////////////////////
/////////Schedule Data///////////////////
// get all schedules
export const GET_SCHEDULES_PER_CUSTOMER = gql`
  query getSchedulePerCustomer($customer_id: UUID) {
    schedules(customer_id: $customer_id) {
      id
      start_time
      end_time
      customer {
        id
        name
      }
      employees {
        name
      }
      days {
        name
      }
      status
    }
  }
`;
export const GET_SCHEDULES = gql`
  query {
    schedules {
      id
      start_time
      end_time
      customer {
        id
        name
      }
      employees {
        name
      }
      days {
        name
      }
      status
    }
  }
`;
// get only single schedule
export const GET_SCHEDULE = gql`
  query getSchedule($id: UUID!) {
    schedule(id: $id) {
      id
      start_time
      end_time
      customer {
        id
        name
      }
    }
  }
`;
// Get single schedule for update schedule....
export const GET_UPDATION_SCHEDULE = gql`
  query getSchedule($id: UUID!) {
    schedule(id: $id) {
      id
      start_time
      end_time
      employees {
        id
        name
      }
      customer {
        id
        name
      }
      days {
        id
        name
      }
    }
  }
`;
// ...Get Days Query.
export const GET_DAYS = gql`
  query {
    days {
      id
      name
    }
  }
`;

//... get data for checkout qrcode  ...//
export const GET_CHECK_OUT_QRCODE_DATA = gql`
  query getCheckOutQRCodeData($id: UUID) {
    me {
      id
      name
    }
    customer: user(id: $id) {
      id
      name
    }
  }
`;
////////
export const GET_CHECK_IN_QRCODE_DATA = gql`
  query getCheckInQRCodeData($id: AUTH_ID) {
    employee: user(id: $id) {
      name
      company {
        name
      }
    }
  }
`;
///-----------------Profile Page Query.....
export const PROFILE_DATA = gql`
  query getProfileData {
    me {
      id
      name
      email
      contact
      address
      currency_id
      tolerance
      role {
        id
        name
      }
      currency{
        id
        name
        symbol
      }
    }
  }
`;
///-----------------Profile Page Query.....
export const CURRENCIES = gql`
  query currencies {
    currencies {
      id
      name
      symbol
    }
  }
`;
